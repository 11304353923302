class Nook {
    constructor(bus) {
        this.bus = bus;

        this.bus.on('title:nook:eula-accepted', (data) =>
            this._setEulaCookie(data.title)
        );
    }

    sendToNook(title) {
        if (Cookies.get('nook-eula-setting') === '1') {
            this._nookAjax(title);
        } else {
            this.bus.trigger('title:nook:request-eula', { titleModel: title });
        }
    }

    _nookAjax(title) {
        title.set('titleState', 'waiting');
        this.bus.trigger('title:nook:start', title);

        return $.ajax({
            url: window.url(window.routes.mediaBorrow, {
                mediaId: title.id,
            }),
            type: 'POST',
        })
            .done((response) => this._nookSuccess(title, response))
            .fail((jqXHR, textStatus, errorThrown) =>
                this._nookFailure(title, jqXHR, textStatus, errorThrown)
            )
            .complete(() => {
                this.bus.trigger('title:nook:end', title);
            });
    }

    _nookSuccess(title) {
        title.set('titleState', 'borrowed');
        this.bus.trigger('title:nook:success', { titleModel: title });
    }

    _nookFailure(title, jqXHR, textStatus, errorThrown) {
        title.set('titleState', 'nook');

        this.bus.trigger('title:nook:fail', {
            jqXHR,
            textStatus,
            errorThrown,
            titleModel: title,
        });
    }

    _setEulaCookie(titleModel) {
        Cookies.set('nook-eula-setting', '1', { sameSite: 'lax' });
        this._nookAjax(titleModel);
    }
}

export default Nook;
